<template>
	<div data-file="index.vue">
		<main>
			<Page
				v-if="template === 'page'"
				:data="content"
			/>
	
			<!-- <ArchivePage
				v-if="template === 'archive'"
				:data="content"
			/>
	
			<Single
				v-if="template === 'single'"
				:data="content"
			/> -->
		</main>
	</div>
</template>

<style lang="scss">
</style>

<script setup>
	import { onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router';
	import { getDevice } from '../utils/device.js';
	import { getEndpointUrl, datalayer } from '#imports';

	const config = useRuntimeConfig();
	const { ACTIVE_MARKET, GTM_KEY, NUXT_PUBLIC_GTAG_ID, SITE_NAME } = config?.public || {};

	// hooks
	const router = useRouter();
	const route = useRoute();
	const { locale } = useI18n();
	const faviconStore = useFavicon();
	const cookie = useCookie(SITE_NAME + '-cookie_analytical');

	const datalayerStore = useDatalayer();
	const { addDatalayer } = datalayerStore;

	const search = route?.query?.s;
	const pageStore = usePage();
	const { addPage } = pageStore;

	const getActiveMarketBoolean = () => {
		if (ACTIVE_MARKET === 'false') {
			return false
		}

		return true;
	}

	const pageRouteName = () => {
		const params = route?.params;
		const slugs = params?.slugs;
 
		if (slugs?.length > 0) {
			const length = slugs?.length || 0;
			let lastItem = slugs[length -1];
			let newSlug = lastItem ? lastItem : slugs[length -2];

			return `${newSlug}`
		}
		
		return 'homepage';
	}
	
	// handle global
	const globalFetchUrl = '/api/global';
	const { data: globalData } = useNuxtData(globalFetchUrl);
	if (!globalData.value) {
		await useFetch(globalFetchUrl, {
			key: globalFetchUrl,
			default: () => {
				return globalData?.value ? globalData.value : {};
			}
		});
	}

	const response = globalData?.value || {};
	if (getActiveMarketBoolean()) {
		const { root_market_redirection } = response;
		await navigateTo({ path: root_market_redirection });
	}

	// handle layout
	const layoutFetchUrl = getEndpointUrl(
		'layout', 
		getActiveMarketBoolean(),
		locale.value,
		'',
		''
	);
	const { data: layoutData } = useNuxtData(layoutFetchUrl);
	if (!layoutData.value) {
		await useFetch(layoutFetchUrl, {
			key: layoutFetchUrl,
			default: () => {
				return layoutData?.value ? layoutData.value : {};
			}
		});
	}
	const layoutResponse = layoutData?.value || {};

	// console.log(' ');
	// console.log(' ');
	// console.log(' ');
	// console.log('--- index ---');
	// console.log('pageRouteName() ', pageRouteName());
	// console.log('layoutFetchUrl ', layoutFetchUrl);
	// console.log('locale.value ', locale.value);
	// console.log('layoutResponse ', layoutResponse);
	// console.log('layoutResponse?.homepage_slug ', layoutResponse?.homepage_slug);

	// handle page
	const pageFetchUrl = getEndpointUrl(
		'content', 
		getActiveMarketBoolean(),
		locale.value,
		layoutResponse?.homepage_slug || pageRouteName(),
		''
	);

	const { data: pageData } = useNuxtData(pageFetchUrl);
	if (!pageData.value) {
		await useFetch(pageFetchUrl, {
			key: pageFetchUrl,
			default: () => {
				return pageData?.value ? pageData.value : {};
			}
		});
	}

	if (pageData?.value?.errorCode) {
		if (pageData.value.errorCode === 404) {
			throw createError({
				statusCode: 404,
				statusMessage: 'Page Not Found'
			})
		}
	}

	// ***************
	// ***************
	// PREPARE PAGE STORE
	let newPageObj = {};

	if (pageData?.value?.market_language_links) {
		newPageObj = {
			...newPageObj,
			links: pageData.value.market_language_links
		}
	}

	if (pageData?.value?.seo) {
		newPageObj = {
			...newPageObj,
			seo: pageData?.value?.seo,
		}
	}

	addPage(newPageObj);

	// ***************
	// ***************
	// REFS
	const content = ref(pageData.value);
	const postType = content?.post_type || 'page';
	const template = content?.template || 'page';

	const seo = ref(pageData.value?.seo);
	const alternate = ref(pageData.value?.alternate);
	const links = ref(alternate?.value?.map((item) => {
    return {
      rel: "alternate",
      hreflang: item.hreflang,
      href: item.href,
    }
  }) || []);

	// ***************
	// ***************
	// DATALAYER
	const getDLpageType = () => {
		return 'homepage';
	}

	const getDatalayer = () => {
		let doFallBack = true;

		// prepare base object used for the data layer
		let eventTypeData = {};
		let eventData = {};

		// ***************
		// lalprobiome
		if (SITE_NAME === 'lalprobiome') {
			doFallBack = false;

			// type
			eventTypeData.event = 'page_view';

			// data
			eventData = {
				'site_language': locale.value,
				'page_type': getDLpageType(),
				'page_title': slugify(`${seo?.value?.title}`),
			}
		}

		// ***************
		// other BU // fallback
		if (doFallBack) {
			eventTypeData.event = 'datalayer-loaded';
			eventData = {
				'page_location': route.path,
				'page_title': pageRouteName(),   
				'pageType': getDLpageType(),
				'language': locale.value,
				'env_channel': getDevice(),
				'page_cat': getDLpageType(),
			};
		}

		const mergeDatalayer = {
			data: {
				...eventTypeData,
				...eventData
			}
		}
		return mergeDatalayer;
	}

	// ***************
	// ***************
	// LIFECYCLE
	onMounted(() => {
		const cookieIsAllowed = cookie?.value === true;
		// **********
		// populate datalayer store so it can be used in every component.
		const datalayerPageType = getDLpageType();
		const datalayerPageLanguage = locale.value;
		const datalayerDevice = getDevice();
		addDatalayer({
			'page_type': datalayerPageType,
			'site_language': datalayerPageLanguage,
			'device': datalayerDevice,
		})

		// **********
		// if tracking is authorized, send datalayer
		if (cookieIsAllowed) {
			// GA4
			if (NUXT_PUBLIC_GTAG_ID !== null && NUXT_PUBLIC_GTAG_ID !== '') {
				datalayer(getDatalayer())
			}

			// GTM
			if (GTM_KEY !== null && GTM_KEY !== '') {
				datalayer(getDatalayer())
			}
		}

		nextTick(() => {
			faviconStore.setActive(false);
		});
	})

	onBeforeRouteLeave((to, from) => {
		faviconStore.setActive(true);
	});

	// ***************
	// ***************
	// SEO
	const { value } = globalData?.value;
	const { favicon } = value || {};

	links.value = [
		...links.value,
		{ 
			rel: 'canonical', 
			href: `${config.public.SITE_URL}${router?.currentRoute?.value?.fullPath}`
		}
	]
	
	for (let key in favicon) {
		links.value = [
			...links.value,
			{
				rel: "icon",
				type: "image/png",
				sizes: key,
				href: favicon[key],
			}
		]
  }

	useHead({
    title: `${seo?.value?.title}`,
    meta: [
      { name: 'description', content: seo?.value?.description },
      { name: 'keywords', content: seo?.value?.keywords },
      { name: 'robots', content: seo?.value?.robots },
			{ property: 'og:title', content: `${seo?.value?.['og:title']}` || `${seo?.value?.title}` },
      { property: 'og:description', content: `${seo?.value?.['og:description']}` || '' },
      { property: 'og:type', content: `${seo?.value?.['og:type']}` || 'website' },
      { property: 'og:url', content: `${config.public.SITE_URL}${router?.currentRoute?.value?.fullPath}` },
      { property: 'og:locale', content: `${seo?.value?.['og:locale']}` || 'en_US' },
      { property: 'og:image', content: `${seo?.value?.['og:image']}` || `${config.public.SITE_URL}/social.png` },
      { property: 'article:published_time', content: `${seo?.value?.['article:published_time']}` || '' },
      { property: 'article:modified_time', content: `${seo?.value?.['article:modified_time']}` || '' },
      { property: 'twitter:card', content: `${seo?.value?.['twitter:card']}` || '' },
      { property: 'twitter:title', content: `${seo?.value?.['twitter:title']}` || `${seo?.value?.title}` },
      { property: 'twitter:description', content: `${seo?.value?.['twitter:description']}` || '' },
      { property: 'twitter:image', content: `${seo?.value?.['twitter:image']}` || '' },
    ],
		htmlAttrs: { lang: locale.value || 'en' },
		link: [
		{ rel: 'icon', type: 'image/png', href: '/favicon.ico' },
			...links.value
		],
		script: [{
			type: 'application/ld+json',
			children: JSON.stringify(seo?.value?.schema),
			seotag: "yes",
			id: 'microdata'
		}]
  });
</script>